.scrollable-dropdown-menu {
  z-index: 9999;
  background-color: white;
  height: 300px;
  max-height: 300px;
  overflow: scroll;
}

.item {
  padding: 5px;
}

.item:hover {
  background: rgba(255, 185, 50, 1.0);
}

.customer-dropdown.btn-group {
  display: flex;
}

.customer-dropdown .search-dropdown {
  flex: 1;
}

.customer-dropdown.btn-group > .dropdown > *:first-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.customer-dropdown.btn-group .search-dropdown .form-control.customer-searchbox {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}