.scrollable-dropdown-menu {
  z-index: 9999;
  background-color: white;
  height: 300px;
  max-height: 300px;
  overflow: scroll;
}

.product-item-create {
  height: 40px;
}

.product-item:hover, .variance-item:hover {
  background: rgba(255, 185, 50, 1.0);
}

.product-name-group {
  background: #CCCCCC;
}

.add-variance-item {
  color: #666666;
}
.add-variance-item:hover {
  background: rgba(255, 185, 50, 1.0);
}