.payment-dropdown.btn-group {
  display: flex;
}

.payment-dropdown .search-dropdown {
  flex: 1;
}

.payment-dropdown.btn-group > .dropdown > *:first-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.payment-dropdown.btn-group .form-control:not(:last-child) {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.payment-dropdown .scrollable-menu {
  height: auto;
  max-height: 600%;
  overflow-x: hidden;
  z-index: 9999;
}