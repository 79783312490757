/* Basic Tag */

.tag {
  border: 1px;
  border-radius: 4px;
  padding: 3px 10px;
  background-color: rgba(50, 50, 185, 1.0);
  color: white;
  margin-right: 5px;
  margin-bottom: 2px;
  white-space: nowrap;
}

.tag .icon {
  padding-right: 8px;
}

.tag .button {
  padding-left: 8px;
  cursor: pointer;
}

/* USJ Tag override */
.tag.usj {
  background-color: rgba(255, 185, 50, 1.0)
}

/* MIJ Tag override */
.tag.mij {
  border: black 0px solid;
  color: red;
  background-color: #F0F0F0F0;
}

/* FB Tag override */
.tag.fb {
  background-color: rgba(59,89,152, 1.0)
}

/* IG Tag override */
.tag.ig {
  background-color: rgb(255, 44, 132)
}

/* Web Tag override */
.tag.web {
  background-color: rgb(0, 90, 48)
}