.scrollable-dropdown-menu {
  z-index: 9999;
  background-color: white;
  height: 300px;
  max-height: 300px;
  overflow: scroll;
}

.item {
  padding: 5px;
}

.item:hover {
  background: rgba(255, 185, 50, 1.0);
}