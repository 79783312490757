.gallery-masonry {
  margin: 0 auto;
}

.gallery-masonry .card {
  width: 296px;
  box-shadow: rgba(0, 0, 0, 0.14902) 0px 2px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.gallery-masonry .card > * {
  width: 100%;
}