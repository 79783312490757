body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.badge-description {
  color: #818182;
  background-color: #e9ecef;
}
.gallery-columns .card {
  box-shadow: rgba(0, 0, 0, 0.14902) 0px 2px 4px;
  /* margin: auto auto 18px auto !important; */
}
.indexof {
  font-size: 0.8em;
  color: gray;
}
.loader-container {
  height: 8em;
  overflow: hidden;
}

.loader,
.loader:before,
.loader:after {
  background: rgba(255, 185, 50, 1.0);
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: rgba(255, 185, 50, 1.0);
  text-indent: -9999em;
  margin: 4em auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
.gallery-masonry {
  margin: 0 auto;
}

.gallery-masonry .card {
  width: 296px;
  box-shadow: rgba(0, 0, 0, 0.14902) 0px 2px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.gallery-masonry .card > * {
  width: 100%;
}
html, body, #root {
  width: 100%;
  height: 100%;
}

.aligner {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.row-separator {
  margin-bottom: 10px;
}
#nprogress .bar {
  background: rgba(255, 185, 50, 1.0) !important;
}

/* Fancy blur effect */
#nprogress .peg {
  box-shadow: 0 0 10px rgba(225, 155, 50, 1.0), 0 0 5px rgba(195, 155, 0, 1.0) !important;
}

.App-Login {
  margin-top: 60px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  max-width: 300px;
  min-width: 100px;
}
/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: 60px;
}
footer.sticky {
  position: fixed;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
  line-height: 60px; /* Vertically center the text there */
  background-color: #f5f5f5;
}


/* Custom page CSS
-------------------------------------------------- */
/* Not required for template or sticky footer method. */

.App-content-container {
  padding-bottom: 60px;
}

.footer > .container {
  padding-right: 15px;
  padding-left: 15px;
}

code {
  font-size: 80%;
}
.isadmin {
  color: #a94442;
}
.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__input-container .form-control[readonly] {
  background-color: white;
}
/* Basic Tag */

.tag {
  border: 1px;
  border-radius: 4px;
  padding: 3px 10px;
  background-color: rgba(50, 50, 185, 1.0);
  color: white;
  margin-right: 5px;
  margin-bottom: 2px;
  white-space: nowrap;
}

.tag .icon {
  padding-right: 8px;
}

.tag .button {
  padding-left: 8px;
  cursor: pointer;
}

/* USJ Tag override */
.tag.usj {
  background-color: rgba(255, 185, 50, 1.0)
}

/* MIJ Tag override */
.tag.mij {
  border: black 0px solid;
  color: red;
  background-color: #F0F0F0F0;
}

/* FB Tag override */
.tag.fb {
  background-color: rgba(59,89,152, 1.0)
}

/* IG Tag override */
.tag.ig {
  background-color: rgb(255, 44, 132)
}

/* Web Tag override */
.tag.web {
  background-color: rgb(0, 90, 48)
}
.scrollable-dropdown-menu {
  z-index: 9999;
  background-color: white;
  height: 300px;
  max-height: 300px;
  overflow: scroll;
}

.item {
  padding: 5px;
}

.item:hover {
  background: rgba(255, 185, 50, 1.0);
}
.scrollable-dropdown-menu {
  z-index: 9999;
  background-color: white;
  height: 300px;
  max-height: 300px;
  overflow: scroll;
}

.item {
  padding: 5px;
}

.item:hover {
  background: rgba(255, 185, 50, 1.0);
}

.customer-dropdown.btn-group {
  display: flex;
}

.customer-dropdown .search-dropdown {
  flex: 1 1;
}

.customer-dropdown.btn-group > .dropdown > *:first-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.customer-dropdown.btn-group .search-dropdown .form-control.customer-searchbox {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.product-name {
  color: black;
}

.variance-name {
  color: #666666;
}
.scrollable-dropdown-menu {
  z-index: 9999;
  background-color: white;
  height: 300px;
  max-height: 300px;
  overflow: scroll;
}

.product-item-create {
  height: 40px;
}

.product-item:hover, .variance-item:hover {
  background: rgba(255, 185, 50, 1.0);
}

.product-name-group {
  background: #CCCCCC;
}

.add-variance-item {
  color: #666666;
}
.add-variance-item:hover {
  background: rgba(255, 185, 50, 1.0);
}
.product-dropdown.btn-group {
  display: flex;
}

.product-dropdown .search-dropdown {
  flex: 1 1;
}

.product-dropdown.input-group > .dropdown > *:first-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.product-dropdown.input-group .search-dropdown .form-control {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.product-dropdown .scrollable-menu {
  height: auto;
  max-height: 600%;
  overflow-x: hidden;
  z-index: 9999;
}
.scrollable-dropdown-menu {
  z-index: 9999;
  background-color: white;
  height: 300px;
  max-height: 300px;
  overflow: scroll;
}

.item {
  padding: 5px;
}

.item:hover {
  background: rgba(255, 185, 50, 1.0);
}
.scrollable-dropdown-menu {
  z-index: 9999;
  background-color: white;
  height: 300px;
  max-height: 300px;
  overflow: scroll;
}

.item {
  padding: 5px;
}

.item:hover {
  background: rgba(255, 185, 50, 1.0);
}
.payment-dropdown.btn-group {
  display: flex;
}

.payment-dropdown .search-dropdown {
  flex: 1 1;
}

.payment-dropdown.btn-group > .dropdown > *:first-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.payment-dropdown.btn-group .form-control:not(:last-child) {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.payment-dropdown .scrollable-menu {
  height: auto;
  max-height: 600%;
  overflow-x: hidden;
  z-index: 9999;
}
.App-content-container {
  margin-top: 60px;
  margin-bottom: 10px;
}

.input-group-text.primary {
  color: rgb(255, 255, 255);
  background-color: rgb(50, 118, 177);
  border-color: rgb(40, 94, 142);
}
.input-group-text.success {
  color: rgb(255, 255, 255);
  background-color: rgb(92, 184, 92);
  border-color: rgb(76, 174, 76);
}
.input-group-text.info {
  color: rgb(255, 255, 255);
  background-color: rgb(57, 179, 215);
  border-color: rgb(38, 154, 188);
}
.input-group-text.warning {
  color: rgb(255, 255, 255);
  background-color: rgb(240, 173, 78);
  border-color: rgb(238, 162, 54);
}
.input-group-text.danger {
  color: rgb(255, 255, 255);
  background-color: rgb(217, 83, 79);
  border-color: rgb(212, 63, 58);
}
